import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

const SectionTitle = styled.h4`
  margin-top: 16px;
  font-size: 26px;
  font-family: SofiaPro;
  font-weight: 500;
  letter-spacing: -0.66px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const SubTitle = styled.h4`
  font-size: 21px;
  margin-top: 16px;
  font-family: SofiaPro;
  font-weight: 500;
  letter-spacing: -0.66px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const Title2 = styled.h3`
  font-size: 34px;
  margin-top: 64px;
  font-weight: 500;
  letter-spacing: -0.66px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const TermsConditions = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title className="text-center">
                  Petaler Terms & Conditions
                </Title>
                <Text variant="small">
                  These Terms govern the use of this Application, and, any other
                  related Agreement or legal relationship with the Company
                  (Petaler) in a legally binding way. The User (you) should read
                  this document carefully.
                </Text>
                <Text variant="small">
                  Although the entire contractual relationship relating to these
                  Products is entered into solely by the Company and Users,
                  Users acknowledge and agree that, where this Application has
                  been provided to them via the Apple App Store or the Google
                  Play Store, Apple and Google may enforce these Terms as a
                  third-party beneficiary.
                </Text>
                <Text variant="small">
                  This Application is provided by: Petaler.
                </Text>
                <Text variant="small">
                  Company contact email: support@getpetaler.com
                </Text>
                <SubTitle>Information about this Application</SubTitle>
                <Text variant="small">
                  Petaler is a piece of software created to enable flower
                  farmers to manage their availability, scheduling, ordering,
                  and process payments from florists.
                </Text>
                <SubTitle>How this Application works</SubTitle>
                <Text variant="small">
                  This Application merely serves as a technical infrastructure
                  or platform to allow Users to interact with each other. The
                  Company therefore is not directly involved in any such
                  interactions between Users.
                </Text>
                <Title2>Terms of Use</Title2>
                <Text variant="small">
                  Unless otherwise specified, the terms of use detailed in this
                  section apply generally when using this Application.{" "}
                </Text>
                <SectionTitle>Account registration</SectionTitle>
                <Text variant="small">
                  The Service is limited to use by commercial cut-flower
                  operations and commercial florists, designers, and other
                  resellers of flowers. The Company will limit access to the
                  Service to those it deems meet these restrictions.
                </Text>
                <Text variant="small">
                  To use the Service, Users must register or create a User
                  account, providing all required data or information in a
                  complete and truthful manner.
                </Text>
                <Text variant="small">
                  Failure to do so will cause unavailability of the Service.
                </Text>
                <Text variant="small">
                  Users are responsible for keeping their login credentials
                  confidential and safe. For this reason, Users are also
                  required to choose passwords that meet the highest standards
                  of strength permitted by this Application.
                </Text>
                <Text variant="small">
                  By registering, Users agree to be fully responsible for all
                  activities that occur under their username and password. Users
                  are required to immediately and unambiguously inform the
                  Company via the contact details indicated in this document, if
                  they think their personal information, including but not
                  limited to User accounts, access credentials or personal data,
                  have been violated, unduly disclosed or stolen.
                </Text>
                <SubTitle>Account termination</SubTitle>
                <Text variant="small">
                  Users can terminate their account and stop using the Service
                  at any time by doing the following:
                  <li>
                    By directly contacting the Company at the contact details
                    provided in this document.
                  </li>
                </Text>
                <SubTitle>Account suspension and deletion</SubTitle>
                <Text variant="small">
                  The Company reserves the right, at its sole discretion, to
                  suspend or delete at any time and without notice, User
                  accounts which it deems inappropriate, not meeting the User
                  requirements, or in violation of these Terms. The suspension
                  or deletion of User accounts shall not entitle Users to any
                  claims for compensation, damages or reimbursement. The
                  suspension or deletion of accounts due to causes attributable
                  to the User does not exempt the User from paying any
                  applicable fees or prices.
                </Text>
                <SectionTitle>Content on this Application</SectionTitle>
                <Text variant="small">
                  Unless where otherwise specified or clearly recognizable, all
                  content available on this Application is owned or provided by
                  the Company or its licensors.
                </Text>
                <Text variant="small">
                  The Company undertakes its utmost effort to ensure that the
                  content provided on this Application infringes no applicable
                  legal provisions or third-party rights. However, it may not
                  always be possible to achieve such a result. In such cases,
                  without prejudice to any legal prerogatives of Users to
                  enforce their rights, Users are kindly asked to preferably
                  report related complaints using the contact details provided
                  in this document.
                </Text>
                <SubTitle>
                  Rights regarding content on this Application - All rights
                  reserved
                </SubTitle>
                <Text variant="small">
                  The Company holds and reserves all intellectual property
                  rights for any such content.
                </Text>
                <Text variant="small">
                  Users may not therefore use such content in any way that is
                  not necessary or implicit in the proper use of the Service.
                </Text>
                <Text variant="small">
                  In particular, but without limitation, Users may not copy,
                  download, share (beyond the limits set forth below), modify,
                  translate, transform, publish, transmit, sell, sublicense,
                  edit, transfer/assign to third parties or create derivative
                  works from the content available on this Application, nor
                  allow any third party to do so through the User or their
                  device, even without the User's knowledge.
                </Text>
                <SubTitle>Content provided by Users</SubTitle>
                <Text variant="small">
                  The Company allows Users to upload, share or provide their own
                  content to this Application.
                </Text>
                <Text variant="small">
                  By providing content to this Application, Users confirm that
                  they are legally allowed to do so and that they are not
                  infringing any statutory provisions and/or third-party rights.
                </Text>
                <SubTitle>Rights regarding content provided by Users</SubTitle>
                <Text variant="small">
                  Content uploaded to the Application by Users, including
                  product photography, remains the property of the User. The
                  User grants permission to the Company to display such content
                  in the Application in a manner that is consistent with the
                  purpose it was uploaded.
                </Text>
                <SubTitle>Liability for provided content</SubTitle>
                <Text variant="small">
                  Users are solely liable for any content they upload, post,
                  share, or provide through this Application. Users acknowledge
                  and accept that the Company does not filter or moderate such
                  content.
                </Text>
                <Text variant="small">
                  However, the Company reserves the right to remove, delete,
                  block or rectify such content at its own discretion and to,
                  without prior notice, deny the uploading User access to this
                  Application:
                  <li>if any complaint based on such content is received;</li>
                  <li>
                    if a notice of infringement of intellectual property rights
                    is received;
                  </li>
                  <li>upon order of a public authority; or</li>
                  <li>
                    where the Company is made aware that the content, while
                    being accessible via this Application, may represent a risk
                    for Users, third parties and/or the availability of the
                    Service.
                  </li>
                  The removal, deletion, blocking or rectification of content
                  shall not entitle Users that have provided such content or
                  that are liable for it, to any claims for compensation,
                  damages or reimbursement. Users agree to hold the Company
                  harmless from and against any claim asserted and/or damage
                  suffered due to content they provided to or provided through
                  this Application.
                </Text>
                <Text variant="small">
                  If the reported content is deemed objectionable, it will be
                  removed within 24 hours and the User who provided the content
                  will be barred from using the Service.
                </Text>
                <SectionTitle>Access to external resources</SectionTitle>
                <Text variant="small">
                  Through this Application Users may have access to external
                  resources provided by third parties. Users acknowledge and
                  accept that the Company has no control over such resources and
                  is therefore not responsible for their content and
                  availability.
                </Text>
                <Text variant="small">
                  Conditions applicable to any resources provided by third
                  parties, including those applicable to any possible grant of
                  rights in content, result from each such third parties’ terms
                  and conditions or, in the absence of those, applicable
                  statutory law.
                </Text>
                <SectionTitle>Software license</SectionTitle>
                <Text variant="small">
                  Any intellectual or industrial property rights, and any other
                  exclusive rights on software or technical applications
                  embedded in or related to this Application are held by the
                  Company and/or its licensors.{" "}
                </Text>
                <Text variant="small">
                  Subject to Users’ compliance with and notwithstanding any
                  divergent provision of these Terms, the Company merely grants
                  Users a revocable, non-exclusive, non-sublicensable and
                  non-transferable license to use the software and/or any other
                  technical means embedded in the Service within the scope and
                  for the purposes of this Application and the Service offered.
                </Text>
                <Text variant="small">
                  {" "}
                  This license does not grant Users any rights to access, usage
                  or disclosure of the original source code. All techniques,
                  algorithms, and procedures contained in the software and any
                  documentation thereto related is the Company’s or its
                  licensors’ sole property. All rights and license grants to
                  Users shall immediately terminate upon any termination or
                  expiration of the Agreement.{" "}
                </Text>
                <Text variant="small">
                  Without prejudice to the above, under this license Users may
                  download, install, use and run the software on the permitted
                  number of devices, provided that such devices are common and
                  up-to-date in terms of technology and market standards.{" "}
                </Text>
                <Text variant="small">
                  The Company reserves the right to release updates, fixes and
                  further developments of this Application and/or its related
                  software and to provide them to Users. Users may need to
                  download and install such updates to continue using this
                  Application and/or its related software.
                </Text>
                <SectionTitle>Liability and indemnification</SectionTitle>
                <SubTitle>Disclaimer of Warranties</SubTitle>
                <Text variant="small">
                  This Application is provided strictly on an “as is” and “as
                  available” basis. Use of the Service is at Users’ own risk. To
                  the maximum extent permitted by applicable law, the Company
                  expressly disclaims all conditions, representations, and
                  warranties — whether express, implied, statutory or otherwise,
                  including, but not limited to, any implied warranty of
                  merchantability, fitness for a particular purpose, or
                  non-infringement of third-party rights. No advice or
                  information, whether oral or written, obtained by user from
                  Company or through the Service will create any warranty not
                  expressly stated herein.
                </Text>
                <Text variant="small">
                  Without limiting the foregoing, the Company, its subsidiaries,
                  affiliates, licensors, officers, directors, agents,
                  co-branders, partners, suppliers and employees do not warrant
                  that the content is accurate, reliable or correct; that the
                  Service will meet Users’ requirements; that the Service will
                  be available at any particular time or location, uninterrupted
                  or secure; that any defects or errors will be corrected; or
                  that the Service is free of viruses or other harmful
                  components. Any content downloaded or otherwise obtained
                  through the use of the Service is downloaded at users own risk
                  and users shall be solely responsible for any damage to Users’
                  computer system or mobile device or loss of data that results
                  from such download or Users’ use of the Service.
                </Text>
                <Text variant="small">
                  The Company does not warrant, endorse, guarantee, or assume
                  responsibility for any product or service advertised or
                  offered by a third party through the Service or any
                  hyperlinked website or service, and the Company shall not be a
                  party to or in any way monitor any transaction between Users
                  and third-party providers of products or services.
                </Text>
                <Text variant="small">
                  The Service may become inaccessible or it may not function
                  properly with Users’ web browser, mobile device, and/or
                  operating system. The Company cannot be held liable for any
                  perceived or actual damages arising from Service content,
                  operation, or use of this Service.
                </Text>
                <Text variant="small">
                  Federal law, some states, and other jurisdictions, do not
                  allow the exclusion and limitations of certain implied
                  warranties. The above exclusions may not apply to Users. This
                  Agreement gives Users specific legal rights, and Users may
                  also have other rights which vary from state to state. The
                  disclaimers and exclusions under this agreement shall not
                  apply to the extent prohibited by applicable law.
                </Text>
                <SubTitle>Limitations of liability</SubTitle>
                <Text variant="small">
                  To the maximum extent permitted by applicable law, in no event
                  shall the Company, and its subsidiaries, affiliates, officers,
                  directors, agents, co-branders, partners, suppliers and
                  employees be liable for
                  <li>
                    any indirect, punitive, incidental, special, consequential
                    or exemplary damages, including without limitation damages
                    for loss of profits, goodwill, use, data or other intangible
                    losses, arising out of or relating to the use of, or
                    inability to use, the Service; and
                  </li>
                  <li>
                    any damage, loss or injury resulting from hacking, tampering
                    or other unauthorized access or use of the Service or User
                    account or the information contained therein;
                  </li>
                  <li>any errors, mistakes, or inaccuracies of content;</li>
                  <li>
                    personal injury or property damage, of any nature
                    whatsoever, resulting from User access to or use of the
                    Service;
                  </li>
                  <li>
                    any unauthorized access to or use of the Company’s secure
                    servers and/or any and all personal information stored
                    therein;
                  </li>
                  <li>
                    any interruption or cessation of transmission to or from the
                    Service;
                  </li>
                  <li>
                    any bugs, viruses, trojan horses, or the like that may be
                    transmitted to or through the Service;
                  </li>
                  <li>
                    any errors or omissions in any content or for any loss or
                    damage incurred as a result of the use of any content
                    posted, emailed, transmitted, or otherwise made available
                    through the Service; and/or
                  </li>
                  <li>
                    the defamatory, offensive, or illegal conduct of any User or
                    third party. In no event shall the Company, and its
                    subsidiaries, affiliates, officers, directors, agents,
                    co-branders, partners, suppliers and employees be liable for
                    any claims, proceedings, liabilities, obligations, damages,
                    losses or costs in an amount exceeding the amount paid by
                    User to the Company hereunder in the preceding 12 months, or
                    the period of duration of this agreement between the Company
                    and User, whichever is shorter.
                  </li>
                  This limitation of liability section shall apply to the
                  fullest extent permitted by law in the applicable jurisdiction
                  whether the alleged liability is based on contract, tort,
                  negligence, strict liability, or any other basis, even if
                  company has been advised of the possibility of such damage.
                </Text>
                <Text variant="small">
                  Some jurisdictions do not allow the exclusion or limitation of
                  incidental or consequential damages, therefore the above
                  limitations or exclusions may not apply to User. The terms
                  give User specific legal rights, and User may also have other
                  rights which vary from jurisdiction to jurisdiction. The
                  disclaimers, exclusions, and limitations of liability under
                  the terms shall not apply to the extent prohibited by
                  applicable law.
                </Text>
                <SubTitle>Indemnification</SubTitle>
                <Text variant="small">
                  The User agrees to defend, indemnify and hold the Company and
                  its subsidiaries, affiliates, officers, directors, agents,
                  co-branders, partners, suppliers and employees harmless from
                  and against any and all claims or demands, damages,
                  obligations, losses, liabilities, costs or debt, and expenses,
                  including, but not limited to, legal fees and expenses,
                  arising from
                  <li>
                    User’s use of and access to the Service, including any data
                    or content transmitted or received by User;
                  </li>
                  <li>
                    User’s violation of these terms, including, but not limited
                    to, User’s breach of any of the representations and
                    warranties set forth in these terms;
                  </li>
                  <li>
                    User’s violation of any third-party rights, including, but
                    not limited to, any right of privacy or intellectual
                    property rights;
                  </li>
                  <li>
                    User’s violation of any statutory law, rule, or regulation;
                  </li>
                  <li>
                    any content that is submitted from User’s account, including
                    third party access with User’s unique username, password or
                    other security measure, if applicable, including, but not
                    limited to, misleading, false, or inaccurate information;
                  </li>
                  <li>User’s willful misconduct; or</li>
                  <li>
                    statutory provision by User or its affiliates, officers,
                    directors, agents, co-branders, partners, suppliers and
                    employees to the extent allowed by applicable law.
                  </li>
                </Text>
                <SectionTitle>Common provisions</SectionTitle>
                <SubTitle>Service interruption</SubTitle>
                <Text variant="small">
                  To ensure the best possible service level, the Company
                  reserves the right to interrupt the Service for maintenance,
                  system updates or any other changes, informing the Users
                  appropriately.
                </Text>
                <Text variant="small">
                  Within the limits of law, the Company may also decide to
                  suspend or terminate the Service altogether. If the Service is
                  terminated, the Company will cooperate with Users to enable
                  them to withdraw Personal Data or information in accordance
                  with applicable law.
                </Text>
                <Text variant="small">
                  Additionally, the Service might not be available due to
                  reasons outside the Company’s reasonable control, such as
                  “force majeure” (eg. labor actions, infrastructural breakdowns
                  or blackouts etc).
                </Text>
                <Text variant="small"></Text>
                <SubTitle>Changes to these Terms</SubTitle>
                <Text variant="small">
                  The Company reserves the right to amend or otherwise modify
                  these Terms at any time. In such cases, the Company will
                  appropriately inform the User of these changes.
                </Text>
                <Text variant="small">
                  Such changes will only affect the relationship with the User
                  for the future.
                </Text>
                <Text variant="small">
                  The continued use of the Service will signify the User’s
                  acceptance of the revised Terms. If Users do not wish to be
                  bound by the changes, they must stop using the Service.
                  Failure to accept the revised Terms, may entitle either party
                  to terminate the Agreement.
                </Text>
                <Text variant="small">
                  The applicable previous version will govern the relationship
                  prior to the User's acceptance. The User can obtain any
                  previous version from the Company.
                </Text>
                <Text variant="small">
                  If required by applicable law, the Company will specify the
                  date by which the modified Terms will enter into force.
                </Text>
                <Text variant="small"></Text>
                <SubTitle>Severability</SubTitle>
                <Text variant="small">
                  Should any provision of these Terms be deemed or become
                  invalid or unenforceable under applicable law, the invalidity
                  or unenforceability of such provision shall not affect the
                  validity of the remaining provisions, which shall remain in
                  full force and effect.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
